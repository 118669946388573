const searchInsrefs = [
  21844, 35207, 35208, 35209, 35187, 35186, 35198, 4567057, 35199, 29934,
  4680265, 5700306, 35267, 35241, 157447, 33326, 39970, 39971, 39972, 35210,
  6021796,
];
const searchOnClickFunction = (e) => {
  console.log(e);
  const { insref, instrumenttype, marketplace } = e;
  if (instrumenttype === 4 || instrumenttype === 9)
    location.href = `detailed.php?insref=${insref}&marketplace=${marketplace}`;
  else if (instrumenttype === 8)
    location.href = `funddetailed.php?insref=${insref}`;
};

export { searchInsrefs, searchOnClickFunction };
