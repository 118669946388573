<template>
	<div :id="props.targetId"></div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
	props: {
        instrument:{
            type:Array,
            defualt:null
        },
        fields:{
            type:Array,
            required:true
        },
        targetId:{
            type:String,
            required:true,
        },
        decimals:{
            type:Number,
            default:0
        },custom_lang:{
            type:Object,
            default:()=>{
                return {}
            }
        }
	},
	setup(props) {
		const buildWidgets = () => {
			MillistreamWidgetSettings.language = "sv";
			MillistreamWidgetSettings.token =
				"b8500098-c785-4b4f-bc0a-4a48e7f65950";
			new Milli_Facts({
				target: document.getElementById(props.targetId),
				instrument: props.instrument,
				fields: props.fields,
                num_decimals:props.decimals,
                link_url:true,
                shortnumbers:true,
				onreadyCallback: function () {},
			});
		};
		onMounted(() => {
			buildWidgets();
		});
		return { props};
	},
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";


@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
