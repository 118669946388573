<template>
  <div class="box-container">
    <div v-if="data.headerText.value" class="box-header">
      <h3>{{ data.headerText.value }}</h3>
    </div>
    <div :id="data.targetId.value"></div>
    <a
      class="link"
      v-if="data.linkHref.value && data.linkText.value"
      :href="data.linkHref.value"
      >{{ data.linkText.value }}</a
    >
  </div>
</template>

<script>
import { onMounted, watch, toRef, toRefs, watchEffect, onUnmounted } from "vue";
export default {
  props: {
    targetId: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
      default: null,
    },
    linkHref: {
      type: String,
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    link_field: {
      type: Array,
      default: () => {
        return ["insref"];
      },
    },
    searchtext: {
      type: String,
    },
    searchfields: {
      type: Array,
    },
    searchstyle: {
      type: Number,
    },
    list: {
      default: null,
      type: Array,
    },
    instrument: {
      default: null,
      type: Array,
    },
    instrumenttype: {
      type: Array,
      default: null,
    },
    instrumentsubtype: {
      type: Array,
      default: null,
    },
    fundcompany: {
      type: Array,
      default: () => {
        return [];
      },
    },
    marketplace: {
      default: null,
      type: Array,
    },
    fields: {
      type: Array,
      required: true,
      default: () => {
        return ["name", "symbol", "diff1dprc"];
      },
    },
    custom_names: {
      type: Object,
      default: () => {
        return {};
      },
    },
    orderby: {
      type: Array,
      default: () => {
        return ["insref"];
      },
    },
    pagination: {
      type: Number,
      default: 0,
    },
    numPaginationButtons: {
      type: Number,
      default: null,
    },
    order: {
      type: Array,
      default: () => {
        return ["asc"];
      },
    },
    controlcolumn: {
      type: Number,
      default: 2,
    },
    stylecolumn: {
      type: Array,
      default: () => {
        return [2];
      },
    },
    individualdiffcolor: {
      type: Boolean,
      default: null,
    },
    custom_lang: {
      type: Object,
      default: () => {
        return null;
      },
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    onreadyCallback: {
      type: Function,
      default: null,
    },
    onRowClick: {
      type: Function,
      default: null,
    },
    maxrows: {
      type: Number,
      default: null,
    },
    millisector: {
      type: Array,
      default: null,
    },
    ppmfunds: {
      type: Boolean,
      default: () => {
        return null;
      },
    },
    dateformat: {
      type: String,
      default: () => {
        return null;
      },
    },
    timeformat: {
      type: String,
      default: "HH:mm",
    },
    constituents: {
      type: Boolean,
      default: false,
    },
    custom_columns: {
      type: Object,
      default: () => {
        return null;
      },
    },
    onDraw: {
      type: Function,
    },
  },
  setup(props) {
    let lista;
    const data = toRefs(props);
    const buildWidget = () => {
      MillistreamWidgetSettings.language = "sv";
      MillistreamWidgetSettings.token = "b8500098-c785-4b4f-bc0a-4a48e7f65950";
      lista = new Milli_List({
        target: document.getElementById(data.targetId.value),
        instrument: data.instrument.value,
        marketplace: data.marketplace.value,
        list: data.list.value,
        tr_data_insref: true,
        instrumenttype: data.instrumenttype.value,
        instrumentsubtype: data.instrumentsubtype.value,
        multiline: false,
        searchtext: data.searchtext.value,
        searchfields: data.searchfields.value,
        searchstyle: data.searchstyle.value,
        maxrows: data.maxrows.value,
        controlcolumn: data.controlcolumn.value,
        fundcompany: data.fundcompany.value,
        num_decimals: 2,
        constituents: data.constituents.value,
        sortable: data.sortable.value,
        individualdiffcolor: data.individualdiffcolor.value,
        custom_lang: data.custom_lang.value,
        custom_names: data.custom_names.value,
        custom_columns: data.custom_columns.value,
        pagination: data.pagination.value,
        forcedecimals: true,
        stylecolumn: data.stylecolumn.value,
        orderby: data.orderby.value,
        order: data.order.value,
        fields: data.fields.value,
        dateformat: data.dateformat.value,
        timeformat: data.timeformat.value,
        numPaginationButtons: data.numPaginationButtons.value,
        millisectorl1: data.millisector.value,
        ppmfunds: data.ppmfunds.value,
        href: data.href.value,
        link_field: data.link_field.value,
        onreadyCallback: data.onreadyCallback.value,
        onRowClick: data.onRowClick.value,
        onDraw: data.onDraw.value,
      });
    };
    watch(
      [
        data.instrument,
        data.list,
        data.millisector,
        data.fields,
        data.custom_lang,
        data.stylecolumn,
        data.controlcolumn,
        data.ppmfunds,
        data.maxrows,
        data.orderby,
        data.fundcompany,
        data.constituents,
        data.custom_columns,
      ],
      (
        [
          instrument_newVal,
          list_newVal,
          millisector_newVal,
          fields_newVal,
          custom_lang_newVal,
          stylecolumn_newVal,
          controlcolumn_newVal,
          ppmfunds_newVal,
          maxrows_newVal,
          orderby_newVal,
          fundcompany_newVal,
          constituents_newVal,
          custom_columns_newVal,
        ],
        [
          instrument_oldVal,
          list_oldVal,
          millisector_oldVal,
          fields_oldVal,
          custom_lang_oldVal,
          stylecolumn_oldVal,
          controlcolumn_oldVal,
          ppmfunds_oldVal,
          maxrows_oldVal,
          orderby_oldVal,
          fundcompany_oldVal,
          constituents_oldVal,
          custom_columns_oldVal,
        ]
      ) => {
        lista.settings.fundcompany = fundcompany_newVal;
        lista.settings.ppmfunds = ppmfunds_newVal;
        lista.settings.controlcolumn = controlcolumn_newVal;
        lista.settings.millisectorl1 = millisector_newVal;
        lista.settings.stylecolumn = stylecolumn_newVal;
        lista.settings.custom_lang = custom_lang_newVal;
        lista.settings.orderby = orderby_newVal;
        lista.settings.list = list_newVal;
        lista.settings.fields = fields_newVal;
        lista.settings.instrument = instrument_newVal;
        lista.settings.maxrows = maxrows_newVal;
        lista.settings.constituents = constituents_newVal;
        lista.settings.custom_columns = custom_columns_newVal;
        lista.drawWidget();
      }
    );
    onMounted(() => {
      buildWidget();
    });
    onUnmounted(() => {});

    return { data };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
